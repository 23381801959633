import axios from 'axios';
import Polygon from '@/models/Polygon';
import OgcBBOX from '@/models/OgcBBOX';

export default {
  apiCall() {
    let call = axios.create({
      baseURL: this.getGeoServerBaseUrl(),
      timeout: 60 * 4 * 1000
    });
    return call;
  },

  getWorkspaceDefault() {
    return 'tbdgd';
  },

  getGeoServerBaseUrl() {
    return process.env.VUE_APP_GEOSERVER_BASE_URL;
  },

  getWmsBaseUrl() {
    return `${this.getWorkspaceDefault()}/wms`;
  },

  getWfsBaseUrl() {
    return `${this.getWorkspaceDefault()}/wfs?service=WFS&version=1.0.0`;
  },

  getDadosLayerUrl(
    layer,
    fields = '',
    filters = '',
    bbox = '',
    maxFeatures = 5000,
    outputFormat = 'application/json',
    filterType = 'cql'
  ) {
    let url = `${this.getWfsBaseUrl()}&request=GetFeature`;

    // Define o layer que será buscado
    const workspace = this.getWorkspaceDefault();
    url += `&typeName=${workspace}:${layer}`;

    // Define quais campos serão retornados
    if (fields) url += `&propertyName=${fields}`;

    if (filterType === 'cql') {
      // Aplica filtro BBOX
      if (filters && bbox) {
        filters += ` AND BBOX(geom,${bbox})`;
      }

      // Filtra os dados do layer
      if (filters) url += `&cql_filter=${filters}`;
    } else {
      // ogc

      // Aplica filtro BBOX
      if (filters && bbox) {
        const ogcBBOX = new OgcBBOX(bbox);
        filters = `<And>${filters}${ogcBBOX.ogcString}</And>`;
      }

      // Filtra os dados do layer
      if (filters) url += `&filter=${filters}`;
    }

    // Limita os dados retornados
    if (maxFeatures > 0) url += `&maxFeatures=${maxFeatures}`;

    // Define o formato de retorno dos dados
    url += `&outputFormat=${outputFormat}`;

    return encodeURI(url);
  },

  getDadosLayer(
    layer,
    fields = '',
    filters = '',
    bbox = '',
    maxFeatures = 5000,
    outputFormat = 'application/json',
    filterType = 'cql'
  ) {
    const url = this.getDadosLayerUrl(
      layer,
      fields,
      filters,
      bbox,
      maxFeatures,
      outputFormat,
      filterType
    );

    return this.apiCall().get(url);
  },

  downloadCsvFeaturesWithinPolygon(
    layer,
    polygonLatLngs,
    filters,
    maxFeatures = 5000
  ) {
    const polygon = new Polygon(polygonLatLngs);

    // Aplica filtro INTERSECTS
    filters += ` AND INTERSECTS(geom, ${polygon.wktString})`;

    let url = this.getDadosLayerUrl(
      layer,
      null, // fields
      filters,
      null, // bbox
      maxFeatures,
      'csv' // outputFormat
    );

    url += `&format_options=filename:${layer}.csv;csvseparator:semicolon`;

    return this.apiCall().get(url);
  },

  downloadCsvFeaturesWithinPolygonUsingOgcFilter(
    layer,
    polygonLatLngs,
    filters,
    maxFeatures = 5000
  ) {
    const polygon = new Polygon(polygonLatLngs);

    // Aplica filtro INTERSECTS
    if (filters)
      filters = `<And>${filters}<Intersects><PropertyName>geom</PropertyName>${polygon.gml2String}</Intersects></And>`;

    let url = this.getDadosLayerUrl(
      layer,
      null, // fields
      filters,
      null, // bbox
      maxFeatures,
      'csv', // outputFormat
      'ogc' // filterType
    );

    url += `&format_options=filename:${layer}.csv;csvseparator:semicolon`;

    return this.apiCall().get(url);
  }
};
