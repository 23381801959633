<template>
  <div>
    <v-autocomplete
      v-model="subestacao"
      :items="subestacoes"
      :loading="loading"
      :label="label"
      :outlined="type === 'outlined'"
      item-value="cod_id"
      item-text="nome"
      :prepend-icon="prependIcon"
      class="mb-0 pb-0"
      no-data-text="Nenhuma subestação encontrada"
      @change="handleSubSelected"
    />
    <mapa-subestacao
      v-if="exibeMapa && bdgdVersao"
      :bdgdVersao="bdgdVersao"
      :subestacao="subestacao"
    />
  </div>
</template>

<script>
import SubestacoesService from '@/services/SubestacoesService';
import MapaSubestacao from '@/components/general/mapas/MapaSubestacao';
export default {
  name: 'SelectSubestacao',
  components: {
    MapaSubestacao
  },
  props: {
    type: {
      type: String,
      default: 'solo'
    },
    label: {
      type: String,
      default: 'Subestação'
    },
    prependIcon: {
      type: String,
      default: 'mdi-transmission-tower'
    },
    bdgdVersao: {
      type: Object,
      required: true
    },
    exibeMapa: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      subestacao: null,
      subestacoes: []
    };
  },
  mounted() {
    this.getSubestacoes();
  },
  methods: {
    getSubestacoes() {
      if (!this.bdgdVersao) return;
      this.loading = true;
      SubestacoesService.getByBdgdVersaoId(this.bdgdVersao.id)
        .then(({ data }) => (this.subestacoes = data))
        .finally(() => (this.loading = false));
    },
    handleSubSelected(subSelected) {
      const index = this.subestacoes.findIndex(
        (subestacao) => subestacao.cod_id === subSelected
      );
      this.subestacao = this.subestacoes[index];
      this.$emit('subSelected', this.subestacao);
    }
  },
  watch: {
    bdgdVersao() {
      this.getSubestacoes();
    }
  }
};
</script>
