<template>
  <div id="visualizar-subestacao"></div>
</template>

<script>
import L from 'leaflet';
import wms from 'leaflet.wms';
import MapboxConfig from '@/config/MapboxConfig';
import mapbox from '@/config/Mapbox';
import GeoServerService from '@/services/GeoServerService';
import scaleMixins from '@/mixins/leaflet/scaleMixins';
import filtersMixins from '@/mixins/geoserver/filtersMixins';

const mapboxConfig = new MapboxConfig(mapbox.access_token, mapbox.style);
const geoserverBaseUrl = GeoServerService.getGeoServerBaseUrl();
const baseUrl = GeoServerService.getWmsBaseUrl();
const workspace = GeoServerService.getWorkspaceDefault();

const ignoredProperties = [
  'GEOM',
  'CONTADOR',
  'COMPANY',
  'COMPANY_ID',
  'MES_DADOS',
  'BDGD_VERSAO',
  'BDGD_VERSAO_ID'
];

export default {
  name: 'MapaSubestacao',
  mixins: [scaleMixins, filtersMixins],
  props: {
    bdgdVersao: {
      type: Object,
      required: true
    },
    subestacao: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    map: null,
    mapaId: 'visualizar-subestacao',
    zoom: 18,
    layerControl: null,
    layer: null
  }),
  mounted() {
    this.configuraMapa(4);
    if (this.subestacao) {
      // Coloca o centro do mapa próximo ao centroid da subestação
      let { longitude, latitude } = this.subestacao;
      this.setView(latitude, longitude);
    } else {
      // Coloca o centro do mapa próximo a capital da empresa em que o usuário está logado
      let capital = this.userLoggedCompany.capital;
      this.setView(capital[1], capital[0]);
    }
    this.map.setZoom(this.zoom);
    this.addBaseLayer();
  },
  computed: {
    userLoggedCompany() {
      return this.$store.getters.getSelectedCompany;
    },
    layerColor() {
      return this.bdgdVersao.cor ?? '#9C27B0';
    },
    sldBodyPolygon() {
      const geoserverLayer = this.geoserverLayer;
      const opacity = 0.5;
      let sldBody = `
<?xml version="1.0" encoding="ISO-8859-1"?>
<StyledLayerDescriptor version="1.0.0"
    xsi:schemaLocation="http://www.opengis.net/sld StyledLayerDescriptor.xsd"
    xmlns="http://www.opengis.net/sld"
    xmlns:ogc="http://www.opengis.net/ogc"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
  <NamedLayer>
    <Name>${workspace}:${geoserverLayer}</Name>
    <UserStyle>
      <Title>${workspace}:${geoserverLayer}</Title>
      <FeatureTypeStyle>
        <Rule>
          <PolygonSymbolizer>
            <Fill>
              <CssParameter name="fill">${this.layerColor}</CssParameter>
              <CssParameter name="fill-opacity">${opacity}</CssParameter>
            </Fill>
            <Stroke>
              <CssParameter name="stroke">#FFFFFF</CssParameter>
              <CssParameter name="stroke-width">2</CssParameter>
            </Stroke>
          </PolygonSymbolizer>
        </Rule>
      </FeatureTypeStyle>
    </UserStyle>
  </NamedLayer>
</StyledLayerDescriptor>
`;
      return sldBody;
    },
    geoserverLayer() {
      return 'view_sub';
    },
    geoserverCqlFilter() {
      if (!(this.subestacao && this.bdgdVersao)) return null;
      return `(cod_id = '${this.subestacao.cod_id}') AND (bdgd_versao_id = ${this.bdgdVersao.id}) AND (company_id = ${this.bdgdVersao.company_id} OR company_id = 0)`;
    },
    geoserverOgcFilter() {
      if (!(this.subestacao && this.bdgdVersao)) return null;
      return `<And><Or><PropertyIsEqualTo><PropertyName>company_id</PropertyName><Literal>${this.bdgdVersao.company_id}</Literal></PropertyIsEqualTo><PropertyIsEqualTo><PropertyName>company_id</PropertyName><Literal>0</Literal></PropertyIsEqualTo></Or><And><PropertyIsEqualTo><PropertyName>cod_id</PropertyName><Literal>${this.subestacao.cod_id}</Literal></PropertyIsEqualTo><PropertyIsEqualTo><PropertyName>bdgd_versao_id</PropertyName><Literal>${this.bdgdVersao.id}</Literal></PropertyIsEqualTo></And></And>`;
    }
  },
  methods: {
    configuraMapa(zoom) {
      this.map = L.map(this.mapaId, {
        fullscreenControl: true,
        loadingControl: true,
        layers: [mapboxConfig.getDefaultLayer()],
        zoom
      });
      this.layerControl = L.control.layers(mapboxConfig.getBaseLayers());
      this.layerControl.addTo(this.map);
      this.createScaleControl(L).addTo(this.map);
    },
    setView(latitude = -2.5606242, longitude = -44.4016494) {
      this.map.setView([latitude, longitude]);
    },
    addBaseLayer() {
      const cqlFilter = this.geoserverCqlFilter;
      const ogcFilter = this.geoserverOgcFilter;
      const geoserverLayer = this.geoserverLayer;
      const sldBody = this.sldBodyPolygon;

      // Se o layer já existe, remove-o
      if (this.layer) {
        this.layerControl.removeLayer(this.layer);
      }

      const MySource = wms.Source.extend({
        showFeatureInfo: (latlng, info) => {
          if (info.includes('no features were found')) {
            return;
          }

          let arrInfo = info.split('\n');
          let stringInfo = '';

          arrInfo.forEach((element, key) => {
            if ([0, 1, arrInfo.length - 1, arrInfo.length - 2].includes(key))
              return;

            if (element.slice(-4) == 'null') return;

            element = element.split('=');
            let property = element[0].trim().toUpperCase();
            if (ignoredProperties.includes(property)) return;

            element = property + ': ' + element[1].trim();
            stringInfo += `${element}<br>`;
          });

          L.popup()
            .setLatLng(latlng)
            .setContent(`<div id="div-popup">${stringInfo}</div>`)
            .openOn(this.map);
        }
      });

      let config = {
        format: 'image/png',
        transparent: true,
        identify: true, // Funcionalidade de clicar na feição e obter os dados,
        sld_body: sldBody
      };

      // Verifica qual tipo de filtro será utilizado, CQL ou OGC e configura o parâmetro correto
      this.isCqlFilter()
        ? (config.cql_filter = cqlFilter)
        : (config.filter = ogcFilter);

      // Cria o layer
      let source = new MySource(`${geoserverBaseUrl}/${baseUrl}`, config);
      this.layer = source.getLayer(`${workspace}:${geoserverLayer}`);

      if (this.subestacao) {
        // Adiciona o layer no mapa
        this.layerControl.addOverlay(
          this.layer,
          `SUB :: ${this.subestacao.cod_id}`
        );
        this.layer.addTo(this.map);

        const { longitude, latitude } = this.subestacao;
        if (longitude && latitude) {
          this.setView(latitude, longitude);
          this.map.setZoom(this.zoom);
        }
      }
    }
  },
  watch: {
    subestacao() {
      this.addBaseLayer();
    }
  }
};
</script>

<style>
#visualizar-subestacao {
  height: 300px;
  z-index: 0;
}
#div-popup {
  max-height: 200px;
  overflow-y: auto;
}
.mapa-info {
  padding: 6px 8px;
  font:
    14px/16px Arial,
    Helvetica,
    sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.mapa-info h4 {
  margin: 0 0 5px;
  color: #777;
}
</style>
