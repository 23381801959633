export default class OgcBBOX {
  constructor(bbox, srid = 4674, geometryColumn = 'geom') {
    this.bbox = bbox;
    this.srid = srid;
    this.geometryColumn = geometryColumn;
  }

  get ogcString() {
    const [x1 = -10, y1 = 0, x2 = 10, y2 = 10] = this.bbox.split(',');

    let ogcString;

    ogcString = '<BBOX>';
    ogcString += `<PropertyName>${this.geometryColumn}</PropertyName>`;
    ogcString += '<Box>';
    ogcString += `<coord><X>${x1}</X><Y>${y1}</Y></coord>`;
    ogcString += `<coord><X>${x2}</X><Y>${y2}</Y></coord>`;
    ogcString += '</Box>';
    ogcString += '</BBOX>';

    return ogcString;
  }
}
