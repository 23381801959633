import APIService from './APIService';

export default {
  getByBdgdVersaoId(bdgdVersaoId) {
    return APIService.apiCall().get(`/subestacoes/get-by-bdgd-versao-id/${bdgdVersaoId}`);
  },
  getEquipamentosNaSubestacao(
    bdgdVersaoId, 
    codigoSubestacao
  ) {
    return APIService.apiCall().get(`/subestacoes/get-equipamentos-na-subestacao/${bdgdVersaoId}/${codigoSubestacao}`);
  },
  getDetalhesEquipamentosInstaladosNaSubestacao(
    bdgdVersaoId, 
    codigoSubestacao, 
    entidadeBdgd
  ) {
    return APIService.apiCall().get(`/subestacoes/get-detalhes-equipamentos-instalados-na-subestacao/${bdgdVersaoId}/${codigoSubestacao}/${entidadeBdgd}`);
  },
  baixarDetalhesEquipamentosInstaladosNaSubestacao(
    bdgdVersaoId, 
    codigoSubestacao, 
    entidadeBdgd, 
    config
  ) {
    return APIService.apiCall().get(
      `/subestacoes/baixar-detalhes-equipamentos-instalados-na-subestacao/${bdgdVersaoId}/${codigoSubestacao}/${entidadeBdgd}`,
      { responseType: 'blob', ...config }
    );
  },
};
