<template>
  <v-row>
    <v-col cols="12">
      <div id="mapa-historico-localizacao-beacon"></div>
      <v-data-table
        :headers="[
          {
            text: 'Latitude',
            value: 'latitude'
          },
          {
            text: 'Longitude',
            value: 'longitude'
          },
          {
            text: 'Primeira em',
            value: 'primeira_localizacao_recebida_em'
          },
          {
            text: 'Última em',
            value: 'ultima_localizacao_recebida_em'
          },
          {
            text: 'Dias na localização',
            value: 'dias_mesma_localizacao'
          },
          {
            sortable: false,
            text: 'Ações',
            value: 'actions',
            class: 'pl-4'
          }
        ]"
        :items="historicoLocalizacao"
        :mobile-breakpoint="800"
        :loading="loadingHistoricoLocalizacao"
        :hide-default-header="loadingHistoricoLocalizacao"
        :hide-default-footer="loadingHistoricoLocalizacao"
        class="mt-4"
      >
        <template v-slot:[`item.latitude`]="{ item }">
          <a
            title="Exibir no mapa"
            @click="() => visualizarMapa(item.latitude, item.longitude)"
            :style="{
              color: coresHistoricoLocalizacao[item.posicao_historico - 1],
              textDecoration: 'underline'
            }"
          >
            {{ item.latitude }}
          </a>
        </template>
        <template v-slot:[`item.longitude`]="{ item }">
          <a
            title="Exibir no mapa"
            @click="() => visualizarMapa(item.latitude, item.longitude)"
            :style="{
              color: coresHistoricoLocalizacao[item.posicao_historico - 1],
              textDecoration: 'underline'
            }"
          >
            {{ item.longitude }}
          </a>
        </template>
        <template v-slot:[`item.primeira_localizacao_recebida_em`]="{ item }">
          {{ item.primeira_localizacao_recebida_em | parseToDateTimeBR }}
        </template>
        <template v-slot:[`item.ultima_localizacao_recebida_em`]="{ item }">
          {{ item.ultima_localizacao_recebida_em | parseToDateTimeBR }}
        </template>
        <template v-slot:[`item.dias_mesma_localizacao`]="{ item }">
          <span v-if="item.dias_mesma_localizacao > 0">
            {{ item.dias_mesma_localizacao | parseNumberToIntegerBR }}
          </span>
          <span v-else-if="item.segundos_mesma_localizacao > 0">
            {{ item.segundos_mesma_localizacao | formatDurationTxt }}
          </span>
          <span v-else>0</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            :color="coresHistoricoLocalizacao[item.posicao_historico - 1]"
            min-width="0"
            class="px-1 ml-n1"
            fab
            icon
            x-small
            title="Exibir no mapa"
            @click="() => visualizarMapa(item.latitude, item.longitude)"
          >
            <v-icon small> mdi-eye-outline </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import L from 'leaflet';
import wms from 'leaflet.wms';
import dayjs from 'dayjs';
import Gradient from 'javascript-color-gradient';
import MapboxConfig from '@/config/MapboxConfig';
import mapbox from '@/config/Mapbox';
import BeaconsService from '@/services/monitoramento-em-tempo-real/BeaconsService';
import GeoServerService from '@/services/GeoServerService';
import browserPrintMixins from '@/mixins/leaflet/browserPrintMixins';
import filtersMixins from '@/mixins/geoserver/filtersMixins';
import '@/plugins/leaflet-icon-glyph-v2';

const mapboxConfig = new MapboxConfig(mapbox.access_token, mapbox.style);
const geoserverBaseUrl = GeoServerService.getGeoServerBaseUrl();
const baseUrl = GeoServerService.getWmsBaseUrl();
const workspace = GeoServerService.getWorkspaceDefault();

const ignoredProperties = [
  'GEOM',
  'CONTADOR',
  'COMPANY',
  'COMPANY_ID',
  'MES_DADOS',
  'BDGD_VERSAO',
  'BDGD_VERSAO_ID'
];

export default {
  name: 'HistoricoLocalizacaoBeacon',
  mixins: [browserPrintMixins, filtersMixins],
  props: {
    beacon: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    map: null,
    loadingHistoricoLocalizacao: false,
    historicoLocalizacao: [],
    layersBeacons: [],
    layerControl: null
  }),
  mounted() {
    this.getHistoricoLocalizacaoBeacon();
    this.configuraMapa(4);
    this.plotaSubestacoes();
  },
  computed: {
    bdgdVersao() {
      return this.$store.getters.getUserSelectedCompanyBdgdVersion;
    },
    subestacoesGeoserverLayerColor() {
      return this.bdgdVersao.cor ?? '#9C27B0';
    },
    subestacoesSldBodyPolygon() {
      const geoserverLayer = this.subestacoesGeoserverLayer;
      const opacity = 0.5;
      let sldBody = `
<?xml version="1.0" encoding="ISO-8859-1"?>
<StyledLayerDescriptor version="1.0.0"
    xsi:schemaLocation="http://www.opengis.net/sld StyledLayerDescriptor.xsd"
    xmlns="http://www.opengis.net/sld"
    xmlns:ogc="http://www.opengis.net/ogc"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
  <NamedLayer>
    <Name>${workspace}:${geoserverLayer}</Name>
    <UserStyle>
      <Title>${workspace}:${geoserverLayer}</Title>
      <FeatureTypeStyle>
        <Rule>
          <PolygonSymbolizer>
            <Fill>
              <CssParameter name="fill">${this.subestacoesGeoserverLayerColor}</CssParameter>
              <CssParameter name="fill-opacity">${opacity}</CssParameter>
            </Fill>
            <Stroke>
              <CssParameter name="stroke">#FFFFFF</CssParameter>
              <CssParameter name="stroke-width">2</CssParameter>
            </Stroke>
          </PolygonSymbolizer>
        </Rule>
      </FeatureTypeStyle>
    </UserStyle>
  </NamedLayer>
</StyledLayerDescriptor>
`;
      return sldBody;
    },
    subestacoesGeoserverLayer() {
      return 'view_sub';
    },
    subestacoesGeoserverCqlFilter() {
      return `(bdgd_versao_id = ${this.bdgdVersao.id}) AND (company_id = ${this.bdgdVersao.company_id} OR company_id = 0)`;
    },
    subestacoesGeoserverOgcFilter() {
      return `<And><Or><PropertyIsEqualTo><PropertyName>company_id</PropertyName><Literal>${this.bdgdVersao.company_id}</Literal></PropertyIsEqualTo><PropertyIsEqualTo><PropertyName>company_id</PropertyName><Literal>0</Literal></PropertyIsEqualTo></Or><And><PropertyIsEqualTo><PropertyName>bdgd_versao_id</PropertyName><Literal>${this.bdgdVersao.id}</Literal></PropertyIsEqualTo></And></And>`;
    },
    coresHistoricoLocalizacao() {
      return new Gradient()
        .setColorGradient('#22c1c3', '#fdbb2d')
        .setMidpoint(this.historicoLocalizacao.length)
        .getColors();
    }
  },
  methods: {
    getHistoricoLocalizacaoBeacon() {
      if (!(this.beacon && this.beacon.id)) return;
      this.loadingHistoricoLocalizacao = true;
      BeaconsService.getHistoricoLocalizacaoBeacon(this.beacon.id)
        .then(({ data }) => (this.historicoLocalizacao = data))
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            'Erro ao recuperar o histórico de localização do beacon.',
            '',
            {
              position: 'topRight'
            }
          );
        })
        .finally(() => (this.loadingHistoricoLocalizacao = false));
    },
    configuraMapa(zoom) {
      this.map = L.map('mapa-historico-localizacao-beacon', {
        fullscreenControl: true,
        layers: [mapboxConfig.getDefaultLayer()],
        zoom
      });
      this.layerControl = L.control.layers(mapboxConfig.getBaseLayers());
      this.layerControl.addTo(this.map);
      this.createBrowserPrintControl(L, 'Beacon').addTo(this.map);
      this.map.setView([-16.6974198, -49.2334694]);
    },
    plotaSubestacoes() {
      const cqlFilter = this.subestacoesGeoserverCqlFilter;
      const ogcFilter = this.subestacoesGeoserverOgcFilter;
      const geoserverLayer = this.subestacoesGeoserverLayer;
      const sldBody = this.subestacoesSldBodyPolygon;

      const MySource = wms.Source.extend({
        showFeatureInfo: (latlng, info) => {
          if (info.includes('no features were found')) {
            return;
          }

          let arrInfo = info.split('\n');
          let stringInfo = '';

          arrInfo.forEach((element, key) => {
            if ([0, 1, arrInfo.length - 1, arrInfo.length - 2].includes(key))
              return;

            if (element.slice(-4) == 'null') return;

            element = element.split('=');
            let property = element[0].trim().toUpperCase();
            if (ignoredProperties.includes(property)) return;

            element = property + ': ' + element[1].trim();
            stringInfo += `${element}<br>`;
          });

          L.popup()
            .setLatLng(latlng)
            .setContent(`<div id="div-popup">${stringInfo}</div>`)
            .openOn(this.map);
        }
      });

      let config = {
        format: 'image/png',
        transparent: true,
        identify: true, // Funcionalidade de clicar na feição e obter os dados,
        sld_body: sldBody
      };

      // Verifica qual tipo de filtro será utilizado, CQL ou OGC e configura o parâmetro correto
      this.isCqlFilter()
        ? (config.cql_filter = cqlFilter)
        : (config.filter = ogcFilter);

      // Cria o layer
      let source = new MySource(`${geoserverBaseUrl}/${baseUrl}`, config);
      const layer = source.getLayer(`${workspace}:${geoserverLayer}`);

      // Adiciona o layer no mapa
      this.layerControl.addOverlay(layer, 'SUB');
      layer.addTo(this.map);
    },
    removeLayersBeacons() {
      this.layersBeacons.forEach((layer) => this.map.removeLayer(layer));
      this.layersBeacons = [];
    },
    plotaHistoricoLocalizacao() {
      this.removeLayersBeacons();
      this.historicoLocalizacao.forEach((localizacaoBeacon) =>
        this.plotaLocalizacaoBeacon(localizacaoBeacon)
      );
    },
    plotaLocalizacaoBeacon(beacon) {
      const {
        posicao_historico,
        localizacao_beacon_geojson,
        latitude,
        longitude,
        ultima_localizacao_recebida_em
      } = beacon;
      const layer = L.geoJSON(JSON.parse(localizacao_beacon_geojson), {
        pointToLayer: (feature, latlng) => {
          return L.marker(latlng, {
            icon: L.icon.glyph({
              iconMDI: 'mdi-map-marker',
              colorBackground:
                this.coresHistoricoLocalizacao[posicao_historico - 1]
            })
          });
        }
      });
      const ultimaLocalizacaoRecebidaEm = dayjs(
        ultima_localizacao_recebida_em
      ).format('DD/MM/YYYY HH:mm');
      const msg = `
<b>Latitude:</b> ${latitude}<br />
<b>Longitude:</b> ${longitude}<br />
<b>Última localização rebecida em:</b> ${ultimaLocalizacaoRecebidaEm}<br />
`;
      layer.bindPopup(msg).addTo(this.map);
      this.layersBeacons.push(layer);
    },
    visualizarMapa(latitude, longitude) {
      this.map.setView([latitude, longitude], 18);
    }
  },
  watch: {
    beacon() {
      this.getHistoricoLocalizacaoBeacon();
    },
    historicoLocalizacao() {
      this.plotaHistoricoLocalizacao();
    }
  }
};
</script>

<style>
#mapa-historico-localizacao-beacon {
  min-height: 600px;
  z-index: 0;
}
</style>
