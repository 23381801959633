var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{attrs:{"id":"mapa-historico-localizacao-beacon"}}),_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":[
        {
          text: 'Latitude',
          value: 'latitude'
        },
        {
          text: 'Longitude',
          value: 'longitude'
        },
        {
          text: 'Primeira em',
          value: 'primeira_localizacao_recebida_em'
        },
        {
          text: 'Última em',
          value: 'ultima_localizacao_recebida_em'
        },
        {
          text: 'Dias na localização',
          value: 'dias_mesma_localizacao'
        },
        {
          sortable: false,
          text: 'Ações',
          value: 'actions',
          class: 'pl-4'
        }
      ],"items":_vm.historicoLocalizacao,"mobile-breakpoint":800,"loading":_vm.loadingHistoricoLocalizacao,"hide-default-header":_vm.loadingHistoricoLocalizacao,"hide-default-footer":_vm.loadingHistoricoLocalizacao},scopedSlots:_vm._u([{key:"item.latitude",fn:function(ref){
      var item = ref.item;
return [_c('a',{style:({
            color: _vm.coresHistoricoLocalizacao[item.posicao_historico - 1],
            textDecoration: 'underline'
          }),attrs:{"title":"Exibir no mapa"},on:{"click":function () { return _vm.visualizarMapa(item.latitude, item.longitude); }}},[_vm._v(" "+_vm._s(item.latitude)+" ")])]}},{key:"item.longitude",fn:function(ref){
          var item = ref.item;
return [_c('a',{style:({
            color: _vm.coresHistoricoLocalizacao[item.posicao_historico - 1],
            textDecoration: 'underline'
          }),attrs:{"title":"Exibir no mapa"},on:{"click":function () { return _vm.visualizarMapa(item.latitude, item.longitude); }}},[_vm._v(" "+_vm._s(item.longitude)+" ")])]}},{key:"item.primeira_localizacao_recebida_em",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseToDateTimeBR")(item.primeira_localizacao_recebida_em))+" ")]}},{key:"item.ultima_localizacao_recebida_em",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseToDateTimeBR")(item.ultima_localizacao_recebida_em))+" ")]}},{key:"item.dias_mesma_localizacao",fn:function(ref){
          var item = ref.item;
return [(item.dias_mesma_localizacao > 0)?_c('span',[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.dias_mesma_localizacao))+" ")]):(item.segundos_mesma_localizacao > 0)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDurationTxt")(item.segundos_mesma_localizacao))+" ")]):_c('span',[_vm._v("0")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"px-1 ml-n1",attrs:{"color":_vm.coresHistoricoLocalizacao[item.posicao_historico - 1],"min-width":"0","fab":"","icon":"","x-small":"","title":"Exibir no mapa"},on:{"click":function () { return _vm.visualizarMapa(item.latitude, item.longitude); }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye-outline ")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }