export default {
  methods: {
    getFilterType() {
      return process.env.VUE_APP_GEOSERVER_FILTER_TYPE;
    },

    isCqlFilter() {
      return this.getFilterType() === 'cql';
    },

    isOgcFilter() {
      return this.getFilterType() === 'ogc';
    },

    getBasicCqlFilter(companyId, bdgdMonth = null, bdgdVersion = null) {
      let cqlFilter = this.getBasicCqlCompanyIdFilter(companyId);

      if (bdgdMonth) cqlFilter += ` and (mes_dados = '${bdgdMonth}')`;

      if (bdgdVersion) cqlFilter += ` and (bdgd_versao = ${bdgdVersion})`;

      return cqlFilter;
    },

    getBasicCqlCompanyIdFilter(companyId) {
      return `(company_id = ${companyId} or company_id = 0)`;
    },

    getBasicOgcFilter(companyId, bdgdMonth = null, bdgdVersion = null) {
      let ogcFilter = '';

      bdgdMonth && bdgdVersion && (ogcFilter += '<And>');

      if (bdgdMonth) {
        ogcFilter += '<PropertyIsEqualTo>';
        ogcFilter += '<PropertyName>mes_dados</PropertyName>';
        ogcFilter += `<Literal>${bdgdMonth}</Literal>`;
        ogcFilter += '</PropertyIsEqualTo>';
      }

      if (bdgdVersion) {
        ogcFilter += '<PropertyIsEqualTo>';
        ogcFilter += '<PropertyName>bdgd_versao</PropertyName>';
        ogcFilter += `<Literal>${bdgdVersion}</Literal>`;
        ogcFilter += '</PropertyIsEqualTo>';
      }

      bdgdMonth && bdgdVersion && (ogcFilter += '</And>');

      return `<And>${this.getBasicOgcCompanyIdFilter(
        companyId
      )}${ogcFilter}</And>`;
    },

    getBasicOgcCompanyIdFilter(companyId) {
      let ogcFilter;

      ogcFilter = '<Or>';
      ogcFilter += '<PropertyIsEqualTo>';
      ogcFilter += '<PropertyName>company_id</PropertyName>';
      ogcFilter += `<Literal>${companyId}</Literal>`;
      ogcFilter += '</PropertyIsEqualTo>';
      ogcFilter += '<PropertyIsEqualTo>';
      ogcFilter += '<PropertyName>company_id</PropertyName>';
      ogcFilter += '<Literal>0</Literal>';
      ogcFilter += '</PropertyIsEqualTo>';
      ogcFilter += '</Or>';

      return ogcFilter;
    }
  }
};
