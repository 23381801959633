<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-map-marker-radius"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light">
              Monitorar Equipamentos
            </div>
          </template>
          <v-row class="mt-5">
            <v-col cols="12">
              <v-alert
                dense
                outlined
                type="info"
                color="blue"
                class="mb-5 py-1 px-1"
              >
                Esta tela exibe no máximo
                <strong>{{
                  numLimiteRegistros | parseNumberToIntegerBR
                }}</strong>
                registros. Utilize o componente de filtros abaixo para
                selecionar os equipamentos e beacons que desejar.
              </v-alert>
              <v-expansion-panels focusable>
                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    Filtrar Equipamentos e Beacons
                    <template v-slot:actions>
                      <v-icon> mdi-filter </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row align="center">
                      <v-col cols="4">
                        <select-subestacao
                          :prependIcon="null"
                          :bdgdVersao="bdgdVersao"
                          @subSelected="
                            (sub) =>
                              (filtros.subestacao = sub ? sub.cod_id : null)
                          "
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                          v-model="filtros.entidades"
                          :items="entidades"
                          multiple
                          label="Equipamentos na BDGD"
                          class="mb-0 pb-0"
                          no-data-text="Nenhum tipo de equipamento encontrado"
                        />
                      </v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col cols="4">
                        <v-textarea
                          v-model="filtros.codigos_subestacoes"
                          label="Subestações"
                          hint="Informe um código de subestação por linha"
                          rows="2"
                          clearable
                          no-resize
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-textarea
                          v-model="filtros.cod_id"
                          label="COD_ID dos Equipamentos"
                          hint="Informe um COD_ID por linha"
                          rows="2"
                          clearable
                          no-resize
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-textarea
                          v-model="filtros.mac_address_beacon"
                          label="MAC Address do Beacon"
                          hint="Informe um MAC Address por linha"
                          rows="2"
                          clearable
                          no-resize
                        />
                      </v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col cols="4">
                        <v-textarea
                          v-model="filtros.mac_address_gateway"
                          label="MAC Address do Gateway"
                          hint="Informe um MAC Address por linha"
                          rows="2"
                          clearable
                          no-resize
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-textarea
                          v-model="filtros.nome_gateway"
                          label="Gateway do Beacon"
                          hint="Informe um nome de gateway por linha"
                          rows="2"
                          clearable
                          no-resize
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-btn
                          color="primary"
                          min-width="100"
                          @click="filtrarEquipamentos"
                          :loading="loadingFiltrarEquipamentos"
                        >
                          Filtrar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <v-col cols="12">
              <div id="mapa-equipamentos"></div>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                class="ml-auto mb-4 break-search"
                label="Procurar"
                hide-details
                single-line
              />
              <v-data-table
                :headers="[
                  {
                    text: '#',
                    value: 'id'
                  },
                  {
                    text: 'MAC Address do Beacon',
                    value: 'mac_address_beacon'
                  },
                  {
                    text: 'Gateway do Beacon',
                    value: 'nome_gateway'
                  },
                  {
                    text: 'Subestação',
                    value: 'codigo_subestacao'
                  },
                  {
                    text: 'Equipamento na BDGD',
                    value: 'entidade'
                  },
                  {
                    text: 'Última localização em',
                    value: 'ultima_localizacao_beacon_em'
                  },
                  {
                    sortable: false,
                    text: 'Ações',
                    value: 'actions',
                    class: 'pl-4'
                  }
                ]"
                :items="detalhesBeacons"
                :search.sync="search"
                :mobile-breakpoint="800"
                :loading="loadingFiltrarEquipamentos"
                :hide-default-header="loadingFiltrarEquipamentos"
                :hide-default-footer="loadingFiltrarEquipamentos"
                no-data-text="Nenhum equipamento encontrado"
              >
                <template v-slot:[`item.mac_address_beacon`]="{ item }">
                  {{ item.mac_address_beacon | toUpperCase }}
                </template>
                <template v-slot:[`item.nome_gateway`]="{ item }">
                  {{ item.nome_gateway | toUpperCase }}
                  ({{ item.mac_address_gateway | formatMacAddress }})
                </template>
                <template v-slot:[`item.codigo_subestacao`]="{ item }">
                  {{ item.codigo_subestacao }} - {{ item.nome_subestacao }}
                </template>
                <template v-slot:[`item.entidade`]="{ item }">
                  {{ item.entidade }} de COD_ID = {{ item.cod_id }}
                </template>
                <template
                  v-slot:[`item.ultima_localizacao_beacon_em`]="{ item }"
                >
                  <span
                    :style="{
                      color:
                        item.segundos_decorridos_desde_ultima_localizacao_beacon >
                        tempoLimiteSemAtualizacaoLocalizacaoBeaconsEmSegundos
                          ? 'red'
                          : '#000'
                    }"
                  >
                    {{ item.ultima_localizacao_beacon_em | parseToDateTimeBR }}
                    <span
                      v-if="
                        item.segundos_decorridos_desde_ultima_localizacao_beacon >
                        0
                      "
                    >
                      ({{
                        item.segundos_decorridos_desde_ultima_localizacao_beacon
                          | formatDurationTxt
                      }})
                    </span>
                  </span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="gray"
                        min-width="0"
                        class="px-1 ml-n1"
                        fab
                        icon
                        x-small
                        title="Exibir no mapa"
                        @click="() => selecionarBeacon(item.id)"
                      >
                        <v-icon small> mdi-eye-outline </v-icon>
                      </v-btn>
                      <v-btn
                        id="action-more"
                        color="white"
                        class="ml-1"
                        height="22px"
                        width="22px"
                        fab
                        x-small
                        elevation="1"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="openDialogCreationLog(item)">
                        <v-list-item-title>
                          <v-icon small> mdi-table-search </v-icon>
                          Log
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-col>
            <v-col
              cols="12"
              v-if="detalhesEquipamento && detalhesEquipamento.numRegistros"
            >
              <v-data-table
                :headers="headerTabelaDetalhesEquipamento"
                :items="detalhesEquipamento.dados"
                :mobile-breakpoint="800"
                :loading="loadingDetalhesEquipamento"
                :hide-default-header="loadingDetalhesEquipamento"
                :hide-default-footer="loadingDetalhesEquipamento"
                no-data-text="Nenhum equipamento encontrado"
              />
            </v-col>
          </v-row>
        </base-material-card>
        <base-material-card
          color="primary"
          icon="mdi-timer-marker-outline"
          inline
          class="px-5 py-3"
          v-if="beaconSelecionado"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light">
              Histórico de localização do Beacon
            </div>
          </template>
          <v-row class="mt-5">
            <v-col cols="12">
              <historico-localizacao-beacon :beacon="beaconSelecionado" />
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
    <dialog-creation-log
      :dialog.sync="dialogCreationLog"
      :item="dialogCreationLogData"
    />
  </v-container>
</template>

<script>
import L from 'leaflet';
import MapboxConfig from '@/config/MapboxConfig';
import mapbox from '@/config/Mapbox';
import BeaconsService from '@/services/monitoramento-em-tempo-real/BeaconsService';
import EntidadesBdgdService from '@/services/EntidadesBdgdService';
import DialogCreationLog from '@/components/general/DialogCreationLog';
import SelectSubestacao from '@/components/general/SelectSubestacao';
import powerupsActionsMixins from '@/mixins/powerupsActionsMixins';
import browserPrintMixins from '@/mixins/leaflet/browserPrintMixins';
import HistoricoLocalizacaoBeacon from '@/components/monitoramento-em-tempo-real/HistoricoLocalizacaoBeacon';
import '@/plugins/leaflet-icon-glyph-v2';

const mapboxConfig = new MapboxConfig(mapbox.access_token, mapbox.style);

export default {
  name: 'MonitorarEquipamentos',
  mixins: [browserPrintMixins, powerupsActionsMixins],
  components: {
    DialogCreationLog,
    SelectSubestacao,
    HistoricoLocalizacaoBeacon
  },
  data: () => ({
    map: null,
    layersSubestacoes: [],
    layersBeacons: [],
    loadingFiltrarEquipamentos: false,
    loadingDetalhesEquipamento: false,
    numLimiteRegistros: 1000,
    search: null,
    entidades: ['EQCR', 'EQRE', 'EQSE', 'EQTRAT', 'EQTRM', 'EQTRMT'],
    detalhesBeacons: [],
    beaconSelecionado: null,
    detalhesEquipamento: {},
    filtros: {
      subestacao: null,
      entidades: [],
      codigos_subestacoes: null,
      cod_id: null,
      mac_address_beacon: null,
      mac_address_gateway: null,
      nome_gateway: null
    },
    tempoAtualizacaoLocalizacaoBeaconsEmSegundos: 60
  }),
  mounted() {
    this.configuraMapa(3);
    this.filtrarEquipamentos();
  },
  computed: {
    bdgdVersao() {
      return this.$store.getters.getUserSelectedCompanyBdgdVersion;
    },
    capitalEmpresa() {
      return this.$store.getters.getCompanyCapitalCoordinates;
    },
    geojsonSubestacoes() {
      let geojsons = {};
      this.detalhesBeacons.forEach(
        (subestacao) => (geojsons[subestacao.codigo_subestacao] = subestacao)
      );
      return geojsons;
    },
    headerTabelaDetalhesEquipamento() {
      return this.detalhesEquipamento.colunas.map((coluna) => ({
        text: coluna.toUpperCase(),
        value: coluna
      }));
    },
    tempoLimiteSemAtualizacaoLocalizacaoBeaconsEmSegundos() {
      return this.tempoAtualizacaoLocalizacaoBeaconsEmSegundos * 10;
    }
  },
  methods: {
    openDialogCreationLog(item) {
      this.dialogCreationLog = true;
      this.dialogCreationLogData = {
        username: item.criado_por,
        created: item.criado_em
      };
    },
    configuraMapa(zoom) {
      this.map = L.map('mapa-equipamentos', {
        fullscreenControl: true,
        layers: [mapboxConfig.getDefaultLayer()],
        zoom
      });
      L.control.layers(mapboxConfig.getBaseLayers()).addTo(this.map);
      this.createBrowserPrintControl(L, 'Equipamentos').addTo(this.map);
      this.posicionaMapaCapitalEmpresa(zoom);
    },
    posicionaMapaCapitalEmpresa(zoom) {
      const [longitude, latitude] = this.capitalEmpresa;
      this.map.setView([latitude, longitude], zoom);
    },
    removeLayersSubestacoes() {
      this.layersSubestacoes.forEach((layer) => this.map.removeLayer(layer));
      this.layersSubestacoes = [];
    },
    removeLayersBeacons() {
      this.layersBeacons.forEach((layer) => this.map.removeLayer(layer));
      this.layersBeacons = [];
    },
    plotaSubestacoes() {
      this.removeLayersSubestacoes();
      const codigosSubestacoes = Object.keys(this.geojsonSubestacoes);
      if (!codigosSubestacoes.length) return false;
      codigosSubestacoes.forEach((codigoSubestacao) =>
        this.plotaSubestacao(this.geojsonSubestacoes[codigoSubestacao])
      );
    },
    plotaSubestacao(subestacao) {
      const { poligono_subestacao, codigo_subestacao, nome_subestacao } =
        subestacao;
      const layer = L.geoJSON(JSON.parse(poligono_subestacao), {
        style: () => ({
          color: this.bdgdVersao.cor ?? '#9C27B0',
          weight: 1
        })
      });
      const msg = `<b>Subestação:</b> ${codigo_subestacao} - ${nome_subestacao}`;
      layer.bindPopup(msg).addTo(this.map);
      this.layersSubestacoes.push(layer);
    },
    plotaBeacons() {
      this.removeLayersBeacons();
      if (!(this.detalhesBeacons && this.detalhesBeacons.length)) return false;
      this.detalhesBeacons.forEach((beacon) => this.plotaBeacon(beacon));
    },
    plotaBeacon(beacon) {
      const { localizacao_beacon, entidade, cod_id, mac_address_beacon } =
        beacon;
      const layer = L.geoJSON(JSON.parse(localizacao_beacon), {
        pointToLayer: (feature, latlng) => {
          return L.marker(latlng, {
            icon: L.icon.glyph({
              iconMDI: 'mdi-map-marker'
            })
          });
        }
      });
      const msg = `
<b>MAC Address:</b> ${mac_address_beacon}<br />
<b>Equipamento:</b> ${entidade}<br />
<b>COD_ID:</b> ${cod_id}
`;
      layer.bindPopup(msg).addTo(this.map);
      this.layersBeacons.push(layer);
    },
    filtrarEquipamentos() {
      this.loadingFiltrarEquipamentos = true;
      BeaconsService.getBeaconsByFiltros(this.filtros, this.numLimiteRegistros)
        .then(({ data }) => (this.detalhesBeacons = data))
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error('Erro ao filtrar os equipamentos.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loadingFiltrarEquipamentos = false));
    },
    selecionarBeacon(beaconId) {
      const beacons = this.detalhesBeacons.filter(
        (beacon) => beacon.id === beaconId
      );
      if (beacons && beacons.length) {
        const beaconSelecionado = beacons[0];
        this.visualizarMapa(beaconSelecionado);
        this.beaconSelecionado = beaconSelecionado;
        this.getDetalhesEquipamento(
          this.bdgdVersao.id,
          beaconSelecionado.entidade,
          beaconSelecionado.cod_id
        );
      }
    },
    visualizarMapa(beaconSelecionado) {
      const localizacaoBeacon = JSON.parse(
        beaconSelecionado.localizacao_beacon
      ).coordinates;
      const [longitude, latitude] = localizacaoBeacon;
      this.map.setView([latitude, longitude], 15);
    },
    getDetalhesEquipamento(bdgdVersaoId, entidade, codId) {
      this.loadingDetalhesEquipamento = true;
      const filtros = [
        {
          campo: 'cod_id',
          funcao: '=',
          valor: codId,
          type: 'character varying'
        }
      ];
      EntidadesBdgdService.getDadosEntidade(entidade, bdgdVersaoId, filtros)
        .then(({ data }) => (this.detalhesEquipamento = data))
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            `Erro ao recuperar os dados do equipamento ${entidade}.`,
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loadingDetalhesEquipamento = false));
    }
  },
  watch: {
    geojsonSubestacoes() {
      this.plotaSubestacoes();
    },
    detalhesBeacons() {
      this.plotaBeacons();
    }
  }
};
</script>

<style>
#mapa-equipamentos {
  min-height: 400px;
  z-index: 0;
}
</style>
