export default class Polygon {
  constructor(latLngs = [], srid = 4674) {
    this.latLngs = latLngs;
    this.srid = srid;
  }

  get wktString() {
    if (this.latLngs.length) {
      const lngLat = this.latLngs.map(({ lat, lng }) => `${lng} ${lat}`);

      const lastPoint = lngLat[0];

      return `SRID=${this.srid};POLYGON((${lngLat.join(', ')}, ${lastPoint}))`;
    }

    return `SRID=${this.srid};POLYGON((0 0))`;
  }

  get gml2String() {
    let coordinates = '0 0';

    if (this.latLngs.length) {
      const lngLat = this.latLngs.map(({ lat, lng }) => `${lng},${lat}`);

      const lastPoint = lngLat[0];

      coordinates = `${lngLat.join(' ')} ${lastPoint}`;
    }

    let gml2String;

    gml2String = '<Polygon srsName="EPSG:4674">';
    gml2String += '<outerBoundaryIs>';
    gml2String += '<LinearRing>';
    gml2String += `<coordinates>${coordinates}</coordinates>`;
    gml2String += '</LinearRing>';
    gml2String += '</outerBoundaryIs>';
    gml2String += '</Polygon>';

    return gml2String;
  }
}
