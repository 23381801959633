import APIService from './APIService';

export default {
  getEntidades() {
    return APIService.apiCall().get('entidades-bdgd/get-entidades');
  },
  getEntidadesGeograficas() {
    return APIService.apiCall().get('entidades-bdgd/get-entidades-geograficas');
  },
  getEntidadesExportacoesManuais() {
    return APIService.apiCall().get(
      'entidades-bdgd/get-entidades-exportacoes-manuais'
    );
  },
  getEntidadesRelevantesCargaSigr() {
    return APIService.apiCall().get(
      'entidades-bdgd/get-entidades-relevantes-carga-sigr'
    );
  },
  getDadosEntidade(entidade, bdgdVersaoId, filtros = {}, limit = 1000) {
    const postData = { filtros, limit };
    return APIService.apiCall().post(
      `/entidades-bdgd/get-dados/${entidade}/${bdgdVersaoId}`,
      JSON.stringify(postData)
    );
  },
  exportaDadosEntidade(
    entidade,
    bdgdVersaoId,
    filtros = {},
    limit = 1000,
    config = {}
  ) {
    const postData = { filtros, limit };
    return APIService.apiCall().post(
      `/entidades-bdgd/exportar-dados/${entidade}/${bdgdVersaoId}/xlsx`,
      JSON.stringify(postData),
      { responseType: 'blob', ...config }
    );
  },
  exportaDadosEntidadeParaShapefile(
    entidade,
    bdgdVersaoId,
    filtros = {},
    limit = 1000,
    config = {}
  ) {
    const postData = { filtros, limit };
    return APIService.apiCall().post(
      `/entidades-bdgd/exportar-dados/${entidade}/${bdgdVersaoId}/shapefile`,
      JSON.stringify(postData),
      { responseType: 'blob', ...config }
    );
  },
  salvarDadoColuna(entidade, linhaId, coluna, dado, tipoDado) {
    return APIService.apiCall().patch(
      `/entidades-bdgd/salvar-dado-coluna/${entidade}/${linhaId}/${coluna}`,
      JSON.stringify({ dado, tipoDado })
    );
  },
  salvarDadoLatitudeLongitude(params) {
    return APIService.apiCall()[params.gid ? 'patch' : 'post'](
      `/entidades-bdgd/salvar-dado-latitude-longitude/${params.entidade}` + (params.gid ? `/${params.gid}` : ''),
      JSON.stringify({ ...params })
    );
  },
  getLogsAjustesPorVersaoBdgd(bdgdVersaoId, limit = 1000) {
    return APIService.apiCall().get(
      `entidades-bdgd/get-logs-ajustes-por-versao-bdgd/${bdgdVersaoId}?limit=${limit}`
    );
  },
  deleteById(entidade, linhaId) {
    return APIService.apiCall().delete(
      `entidades-bdgd/delete-by-id/${entidade.toLowerCase()}/${linhaId}`
    );
  }
};
