import L from 'leaflet';

class MapboxConfig {
  static mapboxUrl =
    'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}';
  static attribution = '';

  constructor(accessToken, defaultLayer = 'streets') {
    this.accessToken = accessToken;
    this.defaultLayer = defaultLayer;
    this.tileSize = 512;
    this.zoomOffset = -1;
    this.maxZoom = 18;
  }

  getDefaultLayer() {
    let layer;
    switch (this.defaultLayer) {
      case 'streets':
        layer = this.getStreetsLayer();
        break;
      case 'outdoors':
        layer = this.getOutdoorsLayer();
        break;
      case 'dark':
        layer = this.getDarkLayer();
        break;
      case 'satellite':
        layer = this.getSatelliteLayer();
        break;
      default:
        layer = this.getLightLayer();
    }
    return layer;
  }

  getStreetsLayer() {
    return this.getLayer('mapbox/streets-v11');
  }

  getOutdoorsLayer() {
    return this.getLayer('mapbox/outdoors-v11');
  }

  getLightLayer() {
    return this.getLayer('mapbox/light-v10');
  }

  getDarkLayer() {
    return this.getLayer('mapbox/dark-v10');
  }

  getSatelliteLayer() {
    return this.getLayer('mapbox/satellite-streets-v11');
  }

  getLayer(id) {
    return L.tileLayer(MapboxConfig.mapboxUrl, {
      id,
      tileSize: this.tileSize,
      zoomOffset: this.zoomOffset,
      maxZoom: this.maxZoom,
      attribution: MapboxConfig.attribution,
      accessToken: this.accessToken
    });
  }

  getBaseLayers() {
    return {
      Ruas: this.getStreetsLayer(),
      Cinza: this.getLightLayer(),
      Satélite: this.getSatelliteLayer(),
      Relevo: this.getOutdoorsLayer(),
      Escuro: this.getDarkLayer()
    };
  }
}
export default MapboxConfig;
