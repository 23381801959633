import APIService from '@/services/APIService.js';

export default {
  getBeacons() {
    return APIService.apiCall().get('/beacons/get-all');
  },
  getBeaconsByFiltros(
    filtros, 
    numLimiteRegistros = 1000
  ) {
    return APIService.apiCall().post(
      `/beacons/get-by-filtros?numLimiteRegistros=${numLimiteRegistros}`, 
      JSON.stringify(filtros)
    );
  },
  getBeaconById(id) {
    return APIService.apiCall().get(`/beacons/get-by-id/${id}`);
  },
  getHistoricoLocalizacaoBeacon(id) {
    return APIService.apiCall().get(`/beacons/get-historico-localizacao-beacon/${id}`);
  },
  salvaBeacon(postData) {
    return APIService.apiCall().post(
      '/beacons/salva-beacon', 
      JSON.stringify(postData)
    );
  },
  excluiBeacon(id) {
    return APIService.apiCall().delete(`/beacons/exclui-beacon/${id}`);
  },
};
